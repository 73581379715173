<script setup>
const emit = defineEmits(["update:likes", "update:isLiked"]);
const props = defineProps([
    "entityType",
    "entityId",
    "isLiked",
    "likes",
    "views",
    "size",
]);

const { executeMutation: insertLike, error } = useInsertLikeMutation();
const { executeMutation: updateLike } = useUpdateLikeMutation();

const like = async (value) => {
    const client = useUser();

    if (!client.value?.client_id) {
        return addModal({
            title: "Войдите в аккаунт",
            body: 'Данное действие доступно только авторизированным пользователям <br><br><a class="button bg-green-600 text-white" href="/auth/signin">Войти</a>',
        });
    }

    const sum = props.likes - (props.isLiked ?? 0);

    if (props.isLiked) {
        value = props.isLiked == value ? null : props.isLiked * -1;
        // Сбрасываем если нажата таже кнопка или изменяем значение если нажата другая
        await updateLike({ id: props.entityId, type: props.entityType, value });
    } else {
        await insertLike({ id: props.entityId, type: props.entityType, value });
        // Если была ошибка вставки значит запись существует, обновляем
        if (error.value)
            await updateLike({
                id: props.entityId,
                type: props.entityType,
                value,
            });
    }

    emit("update:isLiked", value);
    emit("update:likes", sum + (value ?? 0));
};
</script>

<template>
    <span class="button py-1 outline-0 bg-slate-100 gap-2">
        <button class="flex items-center" @click="() => like(1)">
            <Icon
                class=""
                :name="`mdi:thumb-up${isLiked == 1 ? '' : '-outline'}`"
                :size="size"
            />
        </button>
        {{ likes ?? 0 }}
        <button class="flex items-center" @click="() => like(-1)">
            <Icon
                class=""
                :name="`mdi:thumb-down${isLiked == -1 ? '' : '-outline'}`"
                :size="size"
            />
        </button>
    </span>
</template>
